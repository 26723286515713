var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[(_vm.client)?_c('CrmClientDetailsCard',{attrs:{"client":_vm.client,"client-type":_vm.clientType,"settings":_vm.settings,"actions-loading":_vm.clientDetailsActionsLoading,"client-management-situation":_vm.clientManagementSituation},on:{"send-email:click":_vm.onSendEmail,"edit-prospect":_vm.onEditProspect,"transforme-prospect":_vm.onTransformProspect,"delete-prospect":_vm.onDeleteProspect}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('CardDataList',{attrs:{"title":_vm.$t('crm.view.dashboard.activities'),"icon":"mdi-calendar-month-outline","data-list-columns":_vm.eventDataListColumns,"data-list-items":_vm.eventDataListItems,"data-list-item-key":"id","loading":_vm.loadingEvents && !_vm.loadingSummary,"disabled":_vm.disableCards},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.activeClient && _vm.userContactInfo)?_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":_vm.onAddEvent}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('global.add')))],1):_vm._e()]},proxy:true},{key:"appendHead",fn:function(){return [_c('th',{style:({ width: '55px' })},[_vm._v(_vm._s(_vm.$t('crm.view.dashboard.actions')))])]},proxy:true},{key:"appendBody",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onEditEvent(item, index)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CardDataList',{attrs:{"title":_vm.$t('crm.view.dashboard.processes'),"icon":"mdi-sitemap","data-list-columns":_vm.processDataListColumns,"data-list-items":_vm.processDataListItems,"data-list-item-key":"id","loading":_vm.loadingProcesses && !_vm.loadingSummary,"disabled":_vm.disableCards},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.showAddProcess)?_c('v-menu',{attrs:{"bottom":"","auto":"","close-delay":"150","offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('global.add'))+" ")],1)]}}],null,false,516498403)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.processFlowOptions),function(item){return _c('v-list-item',{key:item.id,on:{"click":function($event){return _vm.onAddProcess(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.nome))])],1)}),1)],1):_vm._e()]},proxy:true},{key:"appendHead",fn:function(){return [(_vm.showEditProcess)?_c('th',{style:({ width: '55px' })},[_vm._v(_vm._s(_vm.$t('crm.view.dashboard.actions')))]):_vm._e()]},proxy:true},{key:"appendBody",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.showEditProcess)?_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onEditProcess(item, index)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)],1):_vm._e()]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('CardDataList',{attrs:{"title":_vm.$t('crm.view.dashboard.contact.title'),"icon":"mdi-notebook-outline","data-list-columns":_vm.contactDataListColumns,"data-list-items":_vm.contactDataListItems,"data-list-item-key":"id","loading":_vm.loadingContacts && !_vm.loadingSummary,"disabled":_vm.disableCards},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-spacer'),_c('v-col',{staticClass:"pr-6",attrs:{"cols":"4"}},[_c('v-select',{staticStyle:{"padding":"0px !important"},attrs:{"items":_vm.contactStatusFilterOptions,"item-text":"nome","item-value":"id","hide-details":"true","dense":"","solo":"","height":"28px"},model:{value:(_vm.contactStatus),callback:function ($$v) {_vm.contactStatus=$$v},expression:"contactStatus"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":_vm.onAddContact}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('global.add'))+" ")],1)],1)],1)]},proxy:true},{key:"appendHead",fn:function(){return [_c('th',{style:({ width: '110px', minWidth: '110px' })},[_vm._v(_vm._s(_vm.$t('crm.view.dashboard.actions')))])]},proxy:true},{key:"appendBody",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onEditContact(item, index)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onDeleteContact(item, index)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)]}},{key:"bodyContent",fn:function(ref){
var item = ref.item;
var propName = ref.propName;
return [(propName === 'phone' && item[propName])?_c('div',{staticClass:"d-flex justify-space-between align-center my-1"},[_vm._v(" "+_vm._s(item[propName])+" "),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","dark":"","x-small":"","elevation":"2","width":"24","height":"24","color":"secondary"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-phone-outline")])],1)],1):_vm._e(),(propName === 'phone' && item['whatsapp'])?_c('div',{staticClass:"d-flex justify-space-between align-center my-1"},[_vm._v(" "+_vm._s(item['whatsapp'])+" "),_c('CrmChatCallerButton',{staticClass:"ml-1",attrs:{"elevation":"2","width":"24","height":"24","color":"#25D366","disabled":item.status === _vm.inactiveStatusDescription,"contact":item.model,"contact-number":item['whatsapp']}})],1):_vm._e(),(propName !== 'phone')?_c('div',[_vm._v(" "+_vm._s(item[propName])+" ")]):_vm._e()]}}])})],1)],1)],1)],1),_c('ContentDialog',{attrs:{"title":_vm.$t('crm.view.dashboard.dialog.sendEmail.title'),"max-width":"800px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var parent = ref.parent;
return [_c('CrmEmailView',{attrs:{"client":_vm.activeClient,"client-type":_vm.clientType,"user-contact-info":_vm.userContactInfo},on:{"cancel":parent.close,"complete":parent.close}})]}}]),model:{value:(_vm.dialogConfig.sendEmail.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.sendEmail, "show", $$v)},expression:"dialogConfig.sendEmail.show"}}),_c('ContentDialog',{attrs:{"title":_vm.contactFormDialogTitle,"max-width":"800px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var parent = ref.parent;
return [_c('CrmContactForm',{attrs:{"id":_vm.dialogConfig.contactForm.id,"client":_vm.activeClient},on:{"cancel":parent.close,"complete":function($event){[_vm.onAfterSaveContact($event), parent.close()]}}})]}}]),model:{value:(_vm.dialogConfig.contactForm.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.contactForm, "show", $$v)},expression:"dialogConfig.contactForm.show"}}),_c('ContentDialog',{attrs:{"title":_vm.processKanbanDialogTitle,"max-width":"1600px","dense":""},on:{"after-close":_vm.onAfterKanbanClose},model:{value:(_vm.dialogConfig.processKanban.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.processKanban, "show", $$v)},expression:"dialogConfig.processKanban.show"}},[_c('CrmKanbanViewer',{attrs:{"flow-id":_vm.dialogConfig.processKanban.flow.id,"client-id":_vm.dialogConfig.processKanban.clientId,"client-type":_vm.dialogConfig.processKanban.clientType,"default-queue":_vm.idFila,"process-id":_vm.dialogConfig.processKanban.process && _vm.dialogConfig.processKanban.process.id}})],1),_c('ContentDialog',{attrs:{"title":_vm.$t('crm.view.dashboard.dialog.activityView.title', { clientType: ("" + (this.$t(("crm." + (this.clientType))))) }),"max-width":"100%","dense":""},on:{"after-close":_vm.onAfterActivityViewClose},model:{value:(_vm.dialogConfig.activityView.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.activityView, "show", $$v)},expression:"dialogConfig.activityView.show"}},[(_vm.activeClient && _vm.userContactInfo && _vm.clientType)?_c('CrmActivityView',{attrs:{"client":_vm.activeClient,"client-type":_vm.clientType,"user-contact-info":_vm.userContactInfo,"onload-open":_vm.dialogConfig.activityView.id}}):_vm._e()],1),_c('ContentDialog',{attrs:{"title":_vm.$t('crm.view.dashboard.dialog.prospect.title'),"max-width":"800px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var parent = ref.parent;
return [_c('CrmProspectForm',{attrs:{"client":_vm.activeClient,"id":_vm.dialogConfig.prospect.id},on:{"cancel":parent.close,"complete":function($event){[_vm.onAfterSaveProspect($event), parent.close()]}}})]}}]),model:{value:(_vm.dialogConfig.prospect.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.prospect, "show", $$v)},expression:"dialogConfig.prospect.show"}}),_c('ConfirmationDialog',{attrs:{"msg":_vm.dialogConfig.confirmation.message,"color":_vm.dialogConfig.confirmation.color},on:{"choice":_vm.dialogConfig.confirmation.onChoice},model:{value:(_vm.dialogConfig.confirmation.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.confirmation, "show", $$v)},expression:"dialogConfig.confirmation.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }