var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',{staticClass:"pa-2"},[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.onSetToday}},[_vm._v(_vm._s(_vm.$t('global.today')))]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.onPrevPeriod}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',{staticClass:"mx-3"},[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.onNextPeriod}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('global.day')))])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('global.week')))])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('global.month')))])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"575"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"type":_vm.type,"show-week":"","event-ripple":true},on:{"change":_vm.onCalendarChange,"click:day":_vm.onCreateEvent,"click:event":_vm.onShowEvent,"click:more":_vm.onViewDay,"click:date":_vm.onViewDay,"mousedown:event":_vm.startDrag,"mousedown:time":_vm.startTime,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag.apply(null, arguments)}},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1),_c('v-row',[_c('v-col',{staticClass:"mt-2"}),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"auto"}},[_c('strong',[_vm._v("Cores:")]),_vm._l((_vm.labels),function(label){return _c('v-badge',{key:label.description,staticClass:"ml-1",attrs:{"color":_vm.colors.get(label.colorCode),"inline":"","left":"","dot":""}},[_vm._v(" "+_vm._s(label.description)+" ")])})],2)],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.showEvent),callback:function ($$v) {_vm.showEvent=$$v},expression:"showEvent"}},[_c('CrmActivityCalendarEvent',{attrs:{"event":_vm.activeEvent},scopedSlots:_vm._u([{key:"header-actions",fn:function(ref){
var event = ref.event;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(event.id),expression:"event.id"}],staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.onEditEvent(event)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(event.id),expression:"event.id"}],staticClass:"mr-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.onDeleteEvent(event)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_c('v-menu',{directives:[{name:"show",rawName:"v-show",value:(event.id),expression:"event.id"}],attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(event.styleName !== 'eventGreen'),expression:"event.styleName !== 'eventGreen'"}],attrs:{"link":""},on:{"click":function($event){return _vm.onRescheduleEvent(event)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar-arrow-right")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('global.reschedule'))}})],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.onCloneEvent(event)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('global.duplicate'))}})],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.onSendEmail(event)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('global.sendEmail'))}})],1)],1)],1)],1)]}},{key:"card-actions",fn:function(ref){
var event = ref.event;
var eventClosed = ref.eventClosed;
return [_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.showEvent = false}}},[_vm._v(_vm._s(_vm.$t('global.cancel')))]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","offset-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({directives:[{name:"show",rawName:"v-show",value:(event.id),expression:"event.id"}],attrs:{"text":"","disabled":eventClosed,"loading":_vm.markingAsClose[event.id] || false},on:{"click":_vm.onMarkEventAsClose}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(_vm.$t('crm.activityCalendar.markAsClose'))+" ")],1)]}}],null,true),model:{value:(_vm.showAskForSaleBeforeClose),callback:function ($$v) {_vm.showAskForSaleBeforeClose=$$v},expression:"showAskForSaleBeforeClose"}},[_c('v-card',[_c('v-card-title',{domProps:{"textContent":_vm._s(_vm.$t('crm.activityCalendar.consolidateSale'))}}),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.onCompleteMarkingAsClose(true)}}},[_vm._v(_vm._s(_vm.$t('global.yes')))]),_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.onCompleteMarkingAsClose(true)}}},[_vm._v(_vm._s(_vm.$t('global.no')))])],1)],1)],1)]}}])})],1),_c('ContentDialog',{attrs:{"title":_vm.eventFormDialogTitle,"max-width":"800px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var parent = ref.parent;
return [_c('CrmActivityCalendarEventForm',{attrs:{"default-client":_vm.client,"default-client-type":_vm.clientType,"user-contact-info":_vm.userContactInfo,"id":_vm.dialogConfig.eventForm.id,"clone-id":_vm.dialogConfig.eventForm.cloneId,"start-date":_vm.dialogConfig.eventForm.startDate,"end-date":_vm.dialogConfig.eventForm.endDate,"timed":_vm.dialogConfig.eventForm.timed,"process":_vm.dialogConfig.eventForm.process,"isRescheduling":_vm.isRescheduling,"disable-auto-complete":true},on:{"cancel":parent.close,"complete":function($event){[_vm.onAfterSaveEvent($event), parent.close()]}}})]}}]),model:{value:(_vm.dialogConfig.eventForm.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.eventForm, "show", $$v)},expression:"dialogConfig.eventForm.show"}}),_c('ContentDialog',{attrs:{"title":_vm.sendEventEmailDialogTitle,"max-width":"800px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var parent = ref.parent;
return [_c('CrmActivityCalendarEventEmailView',{attrs:{"client":_vm.client,"event":_vm.dialogConfig.sendEventEmail.event,"user-contact-info":_vm.userContactInfo,"eventModel":_vm.eventModel,"flagEnviarEmail":_vm.flagEnviarEmail},on:{"cancel":function($event){[_vm.onAfterSendEmail(), parent.close()]},"complete":function($event){[_vm.onAfterSendEmail(), parent.close()]}}})]}}]),model:{value:(_vm.dialogConfig.sendEventEmail.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.sendEventEmail, "show", $$v)},expression:"dialogConfig.sendEventEmail.show"}}),_c('ConfirmationDialog',{attrs:{"msg":_vm.dialogConfig.confirmation.message,"color":_vm.dialogConfig.confirmation.color},on:{"choice":_vm.dialogConfig.confirmation.onChoice},model:{value:(_vm.dialogConfig.confirmation.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.confirmation, "show", $$v)},expression:"dialogConfig.confirmation.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }