





























import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { DefaultExtensions } from '@/utils/tiptap-extensions/default-extensions';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import EmailComposer from '@/components/email-composer.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ClientModel from '@/models/crm/client.model';
import { FormHelper } from '@/utils/helpers/form-helper';
import EmailModel from '@/models/email.model';
import ContactService from '@/services/crm/contact.service';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ProspectModel from '@/models/crm/prospect.model';

@Component({
  components: {
    EmailComposer,
  },
})
export default class CrmEmailView extends Vue {
  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  extensions = DefaultExtensions.getExtensionsNoTable();

  formIsValid = true;

  emailModel: EmailModel = plainToClass(EmailModel, {
    cnpj: '',
    prospect: null,
    from: '',
    to: [],
    cc: [],
    subject: '',
    sendCopy: false,
    message: '',
    attachments: [],
  });

  rules = {
    from: [[ValidationRules.arrayLength, 1], ValidationRules.arrayOfEmails],
    to: [[ValidationRules.minArrayLength, 1], ValidationRules.arrayOfEmails],
    cc: [ValidationRules.arrayOfEmails],
    subject: [ValidationRules.required],
    message: [ValidationRules.required],
    attachments: [[ValidationRules.fileMaxSize, 10240]], // 10MB
  };

  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ required: true })
  clientType!: ClientTypeEnum;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  mounted(): void {
    if (this.userContactInfo && this.userContactInfo.email) {
      this.emailModel.from = this.userContactInfo.email;
    }

    if (this.client && this.client.email) {
      this.emailModel.to = [this.client.email];
    }

    this.emailModel.subject = this.getDefaultSubject();
    this.emailModel.message = this.getDefaultMessage();
  }

  async onSend(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.$loading.show({
        container: this.$refs.emailViewContainer,
        canCancel: false,
      });
      try {
        if (this.clientType === ClientTypeEnum.Prospect) {
          const prospect = new ProspectModel();
          prospect.codProspect = this.client.codCliente;
          this.emailModel.prospect = prospect;
        } else {
          this.emailModel.cnpj = this.client.cnpjCpf;
        }

        await this.contactService.sendEmail(this.emailModel);

        this.$notify.success(this.$t('crm.emailView.successfullySent'));

        this.$emit('complete');
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  private getDefaultSubject(): string {
    return this.userContactInfo.nomeEmpresa;
  }

  private getDefaultMessage(): string {
    let message = '';
    if (this.userContactInfo.assinaturaEmail) {
      message += `<br><br>${this.userContactInfo.assinaturaEmail.replaceAll(/(?:\r\n|\r|\n)/g, '<br>')}`;
    }

    return message;
  }
}
