


















































































import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import ContactService from '@/services/crm/contact.service';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '@/components/field.vue';
import ContactModel from '@/models/crm/contact.model';
import ContactTypeModel from '@/models/crm/contact-type.model';
import ClientModel from '@/models/crm/client.model';
import { ObjectHelper } from '@/utils/helpers/object-helper';
import DatePickerField from '@/components/date-picker-field.vue';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({
  components: {
    Field,
    DatePickerField,
  },
})
export default class CrmContactForm extends Vue {
  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  formIsValid = true;

  model: ContactModel = plainToClass(ContactModel, {
    id: null,
    idProspect: null,
    nome: null,
    tipoContato: null,
    email: null,
    telefone: null,
    dataAniversario: null,
    flagAtivo: 1,
  });

  rules = {
    nome: [ValidationRules.required],
    email: [ValidationRules.email],
    telefone: [ValidationRules.phone],
    whatsapp: [ValidationRules.phone],
  };

  contactTypeOptions: ContactTypeModel[] = [];

  telephoneMaskActive = false;

  whatsappMaskActive = false;

  @Prop()
  id!: number;

  @Prop({ required: true })
  client!: ClientModel;

  async mounted(): Promise<void> {
    const isProspect = this.client.type === ClientTypeEnum.Prospect;
    this.model.cnpj = this.client.cnpjCpf;
    this.model.idProspect = isProspect ? parseInt(this.client.codCliente, 10) : undefined;

    const loader = this.setBusyLoader();
    try {
      const getContactTypesTask = this.loadContactTypes();
      const getContactTask = this.loadModel();
      await Promise.all([getContactTypesTask, getContactTask]);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onSave(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        this.model.cnpj = this.client.cnpjCpf;
        let result: ContactModel;

        if (this.id) {
          result = await this.contactService.update(this.model);
        } else {
          result = await this.contactService.create(this.model);
        }

        this.$notify.success(this.$t('crm.contactForm.successfullySave'));

        this.$emit('complete', result);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  get telephoneMask(): string | boolean {
    const parsedNumber = (this.model.telefone || '').replace(/\D/g, '');
    if (parsedNumber.length > 10) {
      return '(##) #####-####';
    }
    return '(##) ####-####';
  }

  get whatsappMask(): string | boolean {
    const parsedNumber = (this.model.whatsapp || '').replace(/\D/g, '');
    if (parsedNumber.length > 10) {
      return '(##) #####-####';
    }
    return '(##) ####-####';
  }

  private async loadModel(): Promise<void> {
    if (!this.id) {
      return;
    }

    const result = await this.contactService.getContact(this.id);
    const mappedObject = ObjectHelper.mapObjectValues<ContactModel>(result, {
      ignore: ['flagMethod', 'inclusaoUsuarioId', 'inclusaoData', 'alteracaoUsuarioId', 'alteracaoData', 'tipo'],
    });

    this.model = plainToClass(ContactModel, mappedObject);

    setTimeout(() => {
      this.telephoneMaskActive = true;
      this.whatsappMaskActive = true;
    }, 50);
  }

  private async loadContactTypes(): Promise<void> {
    this.contactTypeOptions = await this.contactService.getContactTypes();
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.contactFormContainer,
      canCancel: false,
    });
  }
}
