var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"prospectFormContainer",staticClass:"pa-0 pt-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.firstRowCols}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.cnpj'),"rules":_vm.rules.cnpj},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var label = ref.label;
var rules = ref.rules;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(
              (_vm.cnpjMaskActive &&
                (_vm.model.cnpj.length == 17 || (_vm.model.cnpj.length == 14 && !_vm.model.cnpj.includes('.'))) &&
                '##.###.###/####-##') ||
              (_vm.cnpjMaskActive &&
                (_vm.model.cnpj.length == 11 || (_vm.model.cnpj.length == 8 && !_vm.model.cnpj.includes('.'))) &&
                '###.###.###-##')
            ),expression:"\n              (cnpjMaskActive &&\n                (model.cnpj.length == 17 || (model.cnpj.length == 14 && !model.cnpj.includes('.'))) &&\n                '##.###.###/####-##') ||\n              (cnpjMaskActive &&\n                (model.cnpj.length == 11 || (model.cnpj.length == 8 && !model.cnpj.includes('.'))) &&\n                '###.###.###-##')\n            "}],attrs:{"rules":rules,"label":label,"hide-details":"auto"},on:{"focus":function($event){_vm.cnpjMaskActive = true}},model:{value:(_vm.model.cnpj),callback:function ($$v) {_vm.$set(_vm.model, "cnpj", $$v)},expression:"model.cnpj"}})]}}])})],1),(_vm.btnSintegraAtivo)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.stateOptions,"label":_vm.$t('crm.prospectForm.state'),"return-object":false,"hide-details":true,"item-text":"nome","item-value":"sigla","clearable":true},on:{"change":_vm.onStateChange},model:{value:(_vm.model.estado),callback:function ($$v) {_vm.$set(_vm.model, "estado", $$v)},expression:"model.estado"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.firstRowCols}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.situation'),"rules":_vm.rules.situacao},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"rules":rules,"label":label,"items":_vm.statusOptions,"item-text":"name","item-value":"value","return-object":false,"clearable":false,"hide-details":"auto"},model:{value:(_vm.model.situacao),callback:function ($$v) {_vm.$set(_vm.model, "situacao", $$v)},expression:"model.situacao"}})]}}])})],1),(_vm.btnSintegraAtivo)?_c('v-col',{attrs:{"cols":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.cityOptions,"label":_vm.$t('crm.prospectForm.city'),"return-object":true,"hide-details":true,"item-text":"nome","value":_vm.model.cidade,"loading":_vm.cityLoading,"clearable":true},on:{"change":_vm.onCityChange},model:{value:(_vm.model.cidade),callback:function ($$v) {_vm.$set(_vm.model, "cidade", $$v)},expression:"model.cidade"}})],1):_vm._e(),(_vm.btnSintegraAtivo)?_c('v-col',{staticClass:"pt-8",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"color":"secondary","block":"","small":""},on:{"click":_vm.onConsultSintegra}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-magnify")]),_vm._v(_vm._s(_vm.$t('crm.prospectForm.consultSintegra').toString())+" ")],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.name'),"rules":_vm.rules.fantasia},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.fantasia),callback:function ($$v) {_vm.$set(_vm.model, "fantasia", $$v)},expression:"model.fantasia"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.legalName'),"rules":_vm.rules.razaoSocial},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.razaoSocial),callback:function ($$v) {_vm.$set(_vm.model, "razaoSocial", $$v)},expression:"model.razaoSocial"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.contact'),"rules":_vm.rules.contato},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.contato),callback:function ($$v) {_vm.$set(_vm.model, "contato", $$v)},expression:"model.contato"}})]}}])})],1),_c('v-col',{attrs:{"cols":_vm.cellphoneCols}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.telephone'),"rules":_vm.rules.telefone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.telephoneMaskActive && _vm.telephoneMask),expression:"telephoneMaskActive && telephoneMask"}],attrs:{"rules":rules,"label":label,"hide-details":"auto"},on:{"focus":function($event){_vm.telephoneMaskActive = true}},model:{value:(_vm.model.telefone),callback:function ($$v) {_vm.$set(_vm.model, "telefone", $$v)},expression:"model.telefone"}})]}}])})],1),_c('v-col',{attrs:{"cols":_vm.cellphoneCols}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.whatsapp'),"rules":_vm.rules.celular},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.celularMaskActive && _vm.celularMask),expression:"celularMaskActive && celularMask"}],attrs:{"rules":rules,"label":label,"hide-details":"auto"},on:{"focus":function($event){_vm.celularMaskActive = true}},model:{value:(_vm.model.celular),callback:function ($$v) {_vm.$set(_vm.model, "celular", $$v)},expression:"model.celular"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.email'),"rules":_vm.rules.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":_vm.socialsCols}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.facebook'),"rules":_vm.rules.facebook},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.facebook),callback:function ($$v) {_vm.$set(_vm.model, "facebook", $$v)},expression:"model.facebook"}})]}}])})],1),_c('v-col',{attrs:{"cols":_vm.socialsCols}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.instagram'),"rules":_vm.rules.instagram},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.instagram),callback:function ($$v) {_vm.$set(_vm.model, "instagram", $$v)},expression:"model.instagram"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.cep'),"rules":_vm.rules.cep},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###-###'),expression:"'##.###-###'"}],attrs:{"rules":rules,"label":label,"maxlength":"10","hide-details":"auto"},model:{value:(_vm.model.cep),callback:function ($$v) {_vm.$set(_vm.model, "cep", $$v)},expression:"model.cep"}})]}}])})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"pa-0 mt-5",attrs:{"color":"secondary","block":"","small":""},on:{"click":_vm.onConsultCep}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-magnify")]),_vm._v(_vm._s(_vm.$t('crm.prospectForm.consultCorreios').toString())+" ")],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.address'),"rules":_vm.rules.endereco},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.endereco),callback:function ($$v) {_vm.$set(_vm.model, "endereco", $$v)},expression:"model.endereco"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.number'),"rules":_vm.rules.endNumero},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.endNumero),callback:function ($$v) {_vm.$set(_vm.model, "endNumero", $$v)},expression:"model.endNumero"}})]}}])})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.neighborhood'),"rules":_vm.rules.endBairro},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.endBairro),callback:function ($$v) {_vm.$set(_vm.model, "endBairro", $$v)},expression:"model.endBairro"}})]}}])})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.complement'),"rules":_vm.rules.endComplemento},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.endComplemento),callback:function ($$v) {_vm.$set(_vm.model, "endComplemento", $$v)},expression:"model.endComplemento"}})]}}])})],1)],1),_c('v-row',[(!_vm.btnSintegraAtivo)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.stateOptions,"label":_vm.$t('crm.prospectForm.state'),"return-object":false,"hide-details":true,"item-text":"nome","item-value":"sigla","clearable":true},on:{"change":_vm.onStateChange},model:{value:(_vm.model.estado),callback:function ($$v) {_vm.$set(_vm.model, "estado", $$v)},expression:"model.estado"}})],1):_vm._e(),(!_vm.btnSintegraAtivo)?_c('v-col',{attrs:{"cols":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.cityOptions,"label":_vm.$t('crm.prospectForm.city'),"return-object":true,"hide-details":true,"item-text":"nome","loading":_vm.cityLoading,"clearable":true},on:{"change":_vm.onCityChange},model:{value:(_vm.model.cidade),callback:function ($$v) {_vm.$set(_vm.model, "cidade", $$v)},expression:"model.cidade"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.representative'),"rules":_vm.rules.representante},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"v-model":_vm.model.representante,"items":_vm.groupedRepresentatives,"rules":rules,"label":label,"return-object":true,"search-input":_vm.representativeSearch,"no-filter":"","hide-details":"auto"},on:{"update:searchInput":function($event){_vm.representativeSearch=$event},"update:search-input":function($event){_vm.representativeSearch=$event},"change":_vm.onRepresentativeChange},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"pr-2",attrs:{"color":item.code === _vm.model.representante.codigo ? '#333333' : '#00DBC6',"small":""}},[_vm._v(_vm._s(item.representative.municipios.includes(_vm.model.cidade.codIBGE) ? 'mdi-map-marker' : 'mdi-map-marker-off')+" ")]),(item.code === _vm.model.representante.codigo)?_c('span',{staticStyle:{"color":"#333333"}},[_vm._v(" "+_vm._s(item.code + ' - ' + item.name)+" ")]):_c('span',[_vm._v(_vm._s(item.code + ' - ' + item.name))])]}},{key:"selection",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.code + ' - ' + item.name)+" ")]}}],null,true)})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.lead'),"rules":_vm.rules.lead},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-autocomplete',{attrs:{"items":_vm.leadOptions,"rules":rules,"label":label,"item-text":"descricao","return-object":true,"hide-details":"auto"},model:{value:(_vm.model.lead),callback:function ($$v) {_vm.$set(_vm.model, "lead", $$v)},expression:"model.lead"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.observation'),"rules":_vm.rules.observacao},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.observacao),callback:function ($$v) {_vm.$set(_vm.model, "observacao", $$v)},expression:"model.observacao"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.stateInscription'),"rules":_vm.rules.inscricaoEstadual},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.inscricaoEstadual),callback:function ($$v) {_vm.$set(_vm.model, "inscricaoEstadual", $$v)},expression:"model.inscricaoEstadual"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('Field',{attrs:{"label":_vm.$t('crm.prospectForm.suframa'),"rules":_vm.rules.suframa},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var label = ref.label;
            var rules = ref.rules;
return [_c('v-text-field',{attrs:{"rules":rules,"label":label,"hide-details":"auto"},model:{value:(_vm.model.suframa),callback:function ($$v) {_vm.$set(_vm.model, "suframa", $$v)},expression:"model.suframa"}})]}}])})],1)],1),_c('v-row',[(_vm.model.dataCadastro)?_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t('crm.prospectForm.label.registration'))+": "+_vm._s(_vm.registrationUser)+_vm._s(_vm.$d(_vm.model.dataCadastro, 'dateTime'))+" ")]):_vm._e(),(_vm.model.dataAtualizacao)?_c('v-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.$t('crm.prospectForm.label.change'))+": "+_vm._s(_vm.changeUser)+_vm._s(_vm.$d(_vm.model.dataAtualizacao, 'dateTime'))+" ")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.onSave}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.save')))])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.onCancel}},[_c('span',[_vm._v(_vm._s(_vm.$t('global.cancel')))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }